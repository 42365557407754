// src/DataTable.js
import React, { useState, useEffect } from 'react';
import { getMonthName } from './utils';  // Import the function from utils.js
import LineChart from './LineChart';

// Function to check the number is valid
function isValidNumber(value) {
    // Check if the value is not null, not undefined, and not an empty string
    if (value === null || value === undefined || value === "") {
      return false;
    }
  
    // Then check if it's a number and greater than 0
    return typeof value === 'number' && value > 0;
}

function DataTable({ term, amortization_period, amortization_period_months, mortgage_amount, interest_rate, start_date, frequency, frequency_options, fields }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const payment_frequncy_options = frequency_options;

  useEffect(() => {
    // Check if all arguments are valid
    if (!isValidNumber(term) && !isValidNumber(amortization_period) && !isValidNumber(amortization_period_months) && !isValidNumber(mortgage_amount) && !isValidNumber(interest_rate)) {
      console.error("Invalid props provided");
      return;
    }

    // Construct the query string for prepayments
    const prepaymentsQuery = (fields || [])
    .map((field, index) => `prepayments[${index}].moneyValue=${encodeURIComponent(field.moneyValue)}&prepayments[${index}].dateValue=${encodeURIComponent(field.dateValue)}`)
    .join('&');

    // Update the URL to include your query strings
    const url = `/api/calculate?term=${term}&amortization_period=${amortization_period}&amortization_period_months=${amortization_period_months}&mortgage_amount=${mortgage_amount}&interest_rate=${interest_rate}&start_date=${start_date}&frequency=${frequency}&${prepaymentsQuery}`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setData(data);  // Assume dataitems is the array you're interested in
        setIsLoading(false);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [term, amortization_period, amortization_period_months, mortgage_amount, interest_rate, start_date, frequency, fields]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // The total term payments, adding interest and principal together
  const mortgageAmount = +mortgage_amount
  const monthlyPayment = (+data.monthly_payment).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  const totalTermInterest = +data.term_interest
  const totalTermInterestUS = totalTermInterest.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  const totalTermPrincipal = +data.term_principal
  const totalTermPrincipalUS = totalTermPrincipal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  const totalTermPayment = (totalTermInterest + totalTermPrincipal).toFixed(2)
  const totalTermPaymentUS = (+totalTermPayment).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  const endOfTermPrincipal = (mortgageAmount - totalTermPrincipal).toFixed(2)
  const endOfTermPrincipalUS = (+endOfTermPrincipal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  // Percentage calculations
  const pctTermInterest = ((totalTermInterest * 100)/totalTermPayment).toFixed(2)
  const pctTermPrincipal = ((totalTermPrincipal * 100)/totalTermPayment).toFixed(2)
  // The total amortization periods payment
  const amortizationInterest = +data.total_interest
  const totalAmortizationPayment = (mortgageAmount + amortizationInterest).toFixed(2)
  const totalAmortizationPaymentUS = (+totalAmortizationPayment).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  const amortizationInterestFrmt = (amortizationInterest).toFixed(2)
  const amortizationInterestFrmtUS = (+amortizationInterestFrmt).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

  let start_year = start_date.split('-')[0];
  let last_year = null; // Initialize to track the last year processed

  return (
    <div>
      <section>
        <fieldset>
          <legend>Term Interest and Principal Payments</legend>
          <h3>{payment_frequncy_options[(frequency-1)]} Payment: ${monthlyPayment}</h3>

          <label htmlFor="term_interest">Interest Payment:</label> <strong>${totalTermInterestUS}</strong>
          <meter id="term_interest" value={totalTermInterest} min="0" max={totalTermPayment}>{totalTermInterest}</meter>
          <mark>{pctTermInterest}%</mark>
          <br />
          <label htmlFor="term_principal">Principal Payment:</label> <strong>${totalTermPrincipalUS}</strong>
          <meter id="term_principal" value={totalTermPrincipal} min="0" max={totalTermPayment}>{totalTermPrincipal}</meter>
          <mark>{pctTermPrincipal}%</mark>

          <h4>Total Term Payments: ${totalTermPaymentUS}</h4>
          <h4>End of Term Balance: ${endOfTermPrincipalUS}</h4>

        </fieldset>
        <LineChart payment_data={data.payments} />
        <fieldset>
          <legend>Amortization Period Interest and Principal Payments</legend>

          <h4>Amortization Period Interest: ${amortizationInterestFrmtUS}</h4>

          <h4>Amortization Period Total Payment: ${totalAmortizationPaymentUS}</h4>

        </fieldset>
      </section>
      <table>
        {data.payments && data.payments.map((item, index) => {
          const [in_year, in_month, in_day] = item.legible_date.split('-');
          // Convert the date string to a Date object
          const legibleDate = new Date(Number(in_year), Number(in_month) - 1, Number(in_day));
          // Get the month name (abbreviated) and the day
          const monthName = legibleDate.toLocaleString('default', { month: 'short' }); // 'Sep', 'Oct', etc.
          const day = legibleDate.getDate(); // 9, 15, 1, etc.
          const year = legibleDate.getFullYear();

          // Check if the year has changed and include a header if it has
          const yearHeader = (year !== last_year) ? <tr><th>{year}</th><th colSpan="4">&nbsp;</th></tr>: null;
          // Update the lastYear to the current year
          last_year = year;

          // Only have additional payment show on additiona payment detection
          let formattedAdditionalPayment = '';
          if (item.additional_payment > 0 && !isNaN(item.additional_payment))
            formattedAdditionalPayment = `$${(+item.additional_payment).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            item.principal_payment = item.principal_payment + item.additional_payment

          return (
            // For every 12th item (and the first item), render the header
            <>
            {(index === 0) && (
              <tr>
                {/* Adjust these headers to match your data structure */}
                <th>Date</th>
                <th>Interest Payment</th>
                <th>Principal Payment</th>
                <th>Balance</th>
                <th>Additional Payment</th>
              </tr>
            )}
            {yearHeader}
              <tr key={index}>
                <td>{monthName} {day}</td>
                <td>${(+item.interest_payment).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>${(+item.principal_payment).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>${(+item.remaining_balance).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>{formattedAdditionalPayment}</td>
              </tr>
            </>
          ) 
        })}
      </table>
    </div>
  );
}

export default DataTable;
