// src/App.js
import React, { useState } from 'react';
import DataTable from './DataTable';


function App() {
  const [term, setTerm] = useState(5);
  const [amortization_period, setAmortizationPeriod] = useState(25);
  const [amortization_period_months, setAmortizationMonthsPeriod] = useState(0);
  const [mortgage_amount, setMortgageAmount] = useState(100000);
  const [interest_rate, setInterestRate] = useState(6);
  const [start_date, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [frequency, setFrequency] = useState(1);

  const getTomorrowsDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };
  const [tomorrows_date, setTomorrowsDate] = useState(getTomorrowsDate());

  // Additional Payment options & functions
  const [fields, setFields] = useState([{ moneyValue: '', dateValue: '' }]);
  
  const handleChange = (index, event) => {
    const values = [...fields];
    if (event.target.name === 'moneyValue') {
      values[index].moneyValue = event.target.value;
    } else {
      values[index].dateValue = event.target.value;
    }
    setFields(values);
  };

  const handleAdd = () => {
    setFields([...fields, { moneyValue: '', dateValue: '' }]);
  };

  const handleRemove = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  // Calculate the end date based on the start date and term
  const calculateEndDate = (start, termYears) => {
    const start_date = new Date(start);
    start_date.setFullYear(start_date.getFullYear() + termYears);
    return start_date.toISOString().split('T')[0];
  };

  const endDate = start_date ? calculateEndDate(start_date, term) : '';



  // Payment Frequency Options
  const payment_frequncy_options = ['Monthly', 'Weekly', 'Accelerated weekly', 'Bi-weekly', 'Accelerated Bi-weekly', 'Semi-monthly'];

  const handleInterestChange = (e) => {
    setInterestRate(Number(e.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('handleSubmit called', { term, amortization_period, amortization_period_months, mortgage_amount, interest_rate, start_date });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Start Date:
          <input
            type="date"
            value={start_date}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          Term:
          <select value={term} onChange={(e) => setTerm(Number(e.target.value))}>
            {[...Array(10)].map((_, index) => (
              <option key={index} value={index + 1}>{index + 1}</option>
            ))}
          </select> Yrs.
        </label>
        <br />
        <label>
          Amortization Period:
          <input type="number" min="1" max="100" value={amortization_period} onChange={(e) => setAmortizationPeriod(e.target.value)} />Years &nbsp;
          <select value={amortization_period_months} onChange={(e) => setAmortizationMonthsPeriod(Number(e.target.value))}>
            {[...Array(12)].map((_, index) => (
              <option key={index} value={index}>{index}</option>
            ))} 
          </select>Months
        </label>
        <br />
        <label>
          Mortgage Amount:
          <span className="currency-symbol">$</span><input type="number" min="100" max="100000000" value={mortgage_amount} onChange={(e) => setMortgageAmount(e.target.value)} />
        </label>
        <br />
        <label>
          Interest Rate:
          <input
            type="number"
            value={interest_rate}
            onChange={handleInterestChange}
            min="0.05"
            max="25"
            step="0.01"
          /><span className="precentage-symbol">%</span>
          <input
            type="range"
            value={interest_rate}
            onChange={handleInterestChange}
            min="0.05"
            max="25"
            step="0.01"
          />
        </label>
        <br />
        <label>
          Payment Frequency:
          <select value={frequency} onChange={(e) => setFrequency(Number(e.target.value))}>
            {payment_frequncy_options.map((option, index) => (
              <option key={index} value={index + 1}>{option}</option>
            ))}
          </select>
        </label>
        <section>
          <fieldset>
            <legend>Additional Payment Options:</legend>
            {fields.map((field, index) => (
              <div key={index}>
                <label>
                  $:
                  <input
                    type="text"
                    name="moneyValue"
                    value={field.moneyValue}
                    onChange={(e) => handleChange(index, e)}
                    placeholder="Enter amount"
                  />
                </label>
                &nbsp; &nbsp;
                <label>
                  Date:
                  <input
                    type="date"
                    name="dateValue"
                    value={field.dateValue}
                    onChange={(e) => handleChange(index, e)}
                    min={tomorrows_date}
                    max={endDate}
                  />
                </label>
                <button type="button" onClick={() => handleRemove(index)}>Remove</button>
                <br />
              </div>
            ))}
           <button type="button" onClick={handleAdd}>Add Another</button>
          </fieldset>
        </section>
      </form>
      <DataTable frequency_options={payment_frequncy_options} frequency={frequency} term={term} amortization_period={amortization_period} amortization_period_months={amortization_period_months} mortgage_amount={mortgage_amount} interest_rate={interest_rate / 100} start_date={start_date} fields={fields} />
    </div>
  );
}

export default App;
