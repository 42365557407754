import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler // 1. Import Filler plugin
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler // 1. Register Filler plugin
);


const LineChart = ( {payment_data} ) => {

    // Extract labels and datasets from payment_data.payments
    const labels = payment_data ? payment_data.map(item => item.legible_date) : [];
    const interest_payment = payment_data ? payment_data.map(item => item.interest_payment) : [];
    const principal_payment = payment_data ? payment_data.map(item => item.principal_payment) : [];

    // Log dataset2Data to the console
    useEffect(() => {
        console.log('Interest Data:', payment_data);
    }, [payment_data]); // Only runs when dataset2Data changes
    
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Principal',
                data: principal_payment,
                borderColor: 'rgba(75, 192, 192, 0.25)',
                borderWidth: 2,
                backgroundColor: 'rgba(75, 192, 192, 0.25)',
                fill: "origin", // Fill the area under the line
            },
            {
                label: 'Interest',
                data: interest_payment,
                borderColor: 'rgba(153, 102, 255, 0.25)',
                borderWidth: 2,
                backgroundColor: 'rgba(153, 102, 255, 0.25)', // Translucent fill color
                fill: {
                  target: "origin", // 3. Set the fill options
                  //above: "rgba(255, 0, 0, 0.3)"
                }
            },
        ],
    };

    const options = {
        responsive: true,
        tension: 0.3, // 2. Set the tension (curvature) of the line to your liking.  (You may want to lower this a smidge.)
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Term Interest and Principal payments',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
      <div style={{ maxWidth: '800px', margin: '0' }}> {/* Set max-width here */}
        <Line data={data} options={options} />
      </div>
    );
};

export default LineChart;
